import Payment from '@/api/student/payments/payment';
import fileDownload from "js-file-download";

const payment = new Payment();

const paymentModule = {

    namespaced: true,
    state: {
        item: null,
        items: [],
        total: 0,
        lookups: null,
        details: null,
        load: false,
        installmentId: null
    },
    getters: {
        items(state) {
            return state.items;
        },
        item(state) {
            return state.item;
        },
        load(state) {
            return state.load;
        },
        total(state) {
            return state.total;
        },
        status(state) {
            return state.item.removed;
        },
        lookups(state) {
            return state.lookups;
        },
        details(state) {
            return state.details;
        },
        transaction(state) {
            return state.transaction;
        },
        installmentId(state) {
            return state.installmentId;
        }
    },
    mutations: {
        SET_ITEMS: (state, users) => {
            state.items = users;
        },
        SET_TOTAL_ITEMS: (state, total) => {
            state.total = total;
        },
        SET_ITEMS_LOAD: (state, load) => {
            state.load = load;
        },
        SET_ITEM: (state, user) => {
            state.item = user;
        },
        SET_LOOKUPS(state, lookups) {
            state.lookups = lookups;
        },
        SET_DETAILS(state, details) {
            state.details = details;
        },
        SET_TRANSACTION(state, transaction) {
            state.transaction = transaction;
        },
        SET_INSTALLMENT_ID(state, installmentId) {
            state.installmentId = installmentId;
        },
    },
    actions: {
        payments({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                payment.list(payload.query)
                    .then(response => {
                        commit('SET_ITEMS', response.data);
                        commit('SET_TOTAL_ITEMS', response.meta.count);
                        commit('SET_DETAILS', response.meta.details);
                        commit('SET_LOOKUPS', response.meta.lookup);
                        commit('SET_ITEMS_LOAD', false);
                        resolve();
                    })
            });
        },
        put({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                payment.put(payload.id, payload.query).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
        printCashInstructions({ commit }, query) {
            return new Promise((resolve, reject) => {
                payment
                  .printData(query)
                  .then(response => {
                    fileDownload(response, 'Cash_Instructions.pdf')
                  })
                  .catch(error => {
                    reject(error);
                  })
            })
        },
        putTransaction({ commit }, payload) {
            commit('app/UPDATE_LOAD', true, { root: true });
            return new Promise((resolve, reject) => {
                payment.putTransaction(payload.id, payload.query).then(response => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    resolve();
                }).catch(error => {
                    commit('app/UPDATE_LOAD', false, { root: true });
                    reject(error);
                });
            });
        },
        pay({ commit }, payload) {
            commit('SET_ITEMS_LOAD', true);
            return new Promise((resolve, reject) => {
                payment.pay(payload.id, payload.query).then(response => {
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                }).catch(error => {
                    commit('SET_ITEMS_LOAD', false);
                    reject(error);
                });
            });
        },
        payWithKlcikit({ commit }, paymentId) {
            commit('SET_ITEMS_LOAD', true);
            return new Promise((resolve, reject) => {
                payment.payWithKlcikit(paymentId).then(response => {
                    commit('SET_ITEMS_LOAD', false);
                    resolve(response);
                }).catch(error => {
                    commit('SET_ITEMS_LOAD', false);
                    reject(error);
                });
            });
        },
        get({ commit }, payload) {
            return new Promise((resolve, reject) => {
                commit('SET_ITEMS_LOAD', true);
                payment.get(payload.id,payload.installment_id).then(response => {
                    commit('SET_ITEM', response.data);
                    commit('SET_ITEMS_LOAD', false);
                    resolve();
                })
            });
        },
        remove({ commit }, id) {
            return new Promise((resolve, reject) => {
                payment.remove(id).then(response => {
                    resolve();
                })
            });
        },
        restore({ commit }, id) {
            return new Promise((resolve, reject) => {
                payment.restore(id).then(response => {
                    resolve();
                })
            });
        },
        changeInstallmentId({ commit }, id){
            commit('SET_INSTALLMENT_ID', id);
        }
    },
};

export default paymentModule;
